import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/video-difusion.png";
import video from "../../../../static/img/blog/video-difusion-1140.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='¿Video, la nueva ola de difusión para inversionistas?'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={video}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                13 Ene. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : J.A. Lovera
                </span>
                                <span>
                    Herramientas de RI
                </span>
                            </div>
                        </div>

                        <h2>¿Video, la nueva ola de difusión para inversionistas?</h2>
                        <br/>
                        <h6>¿El uso de webcasts con tecnología de video streaming, en vez del uso de los clásicos
                            servicios
                            telefónicos de conference call, es visualizado en México como una práctica sofisticada de
                            uso no
                            extendido, y no necesariamente valorada por los inversionistas ¿es ésto cierto? ¿Qué tan
                            reciente y
                            profundo es el uso del video streaming como instrumento de difusión de Relaciones con
                            Inversionistas (RI)
                            en otros mercados?</h6>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>
                        La penetración del uso de webcasts con video streaming hacia 2001 era ya del 51% en las empresas
                        comprendidas en el listado del Fortune 100 (U.S.), mientras que en el listado comprendido por el
                        FTSE 100
                        alcanzaba el 86%* (U.K.), dicha penetración ha aumentado y se ha esparcido hacia small y medium
                        caps. En el
                        caso del mercado mexicano tan sólo un puñado de empresas lleva a cabo esta práctica, lo que en
                        cierto
                        sentido indica que estamos a casi tres lustros de distancia de mercados más desarrollados en
                        este respecto.
                    </p>
                    <p>Detrás del atraso del mercado mexicano en el uso del video streaming vemos varios factores. En
                        primera
                        instancia, la implementación de prácticas americanas al mercado mexicano; en U.S. el uso del
                        video streaming
                        en webcasts fue más lento que en Europa y Canadá, pues estos países tenían una mayor penetración
                        de
                        internet, así como mayor ancho de banda, aunado a que muchas empresas americanas consideraron al
                        uso de
                        video-webcasts como un mero formalismo de compliance más que como un instrumento útil de
                        difusión.</p>
                    <p>Los otros factores están dados por la reticencia del senior management a tener una mayor
                        exposición, así
                        como por cuestionamientos sobre la amplitud de la audiencia en el uso de videos y sobre la
                        plataforma
                        tecnológica necesaria para soportarlos. Hoy en día, vemos muy poca validez en estos argumentos;
                        ya que,
                        Youtube tiene más de 147 mills. de usuarios, con un consumo de 101 videos per cápita, por lo que un video cuenta con 53
                        veces mayor
                        probabilidad de ser encontrado en una búsqueda de internet sobre una página de “sólo
                        texto”**.
                        De acuerdo con PR Newswire**, existe una demanda cada vez mayor de los inversionistas por
                        ambientes de
                        familiaridad y confianza, lo que a su vez requiere de una mayor exposición del senior
                        management. En lo
                        referente a la plataforma de video-streaming, existe toda la infraestructura necesaria a bajo
                        costo para que
                        las empresas puedan desplegar el uso de video sin complicación alguna; enlaces de uso doméstico
                        ya alcanzan
                        los 200 Mbs. en México.</p>
                    <p>Una muestra clara del poder del video streaming como una poderosa herramienta de RI fue dada
                        por Yahoo en su reporte del 2T13, donde Marissa Mayers dio una amplia exposición de los
                        resultados, a través
                        del video, a la par que su equipo de RI atendía múltiples tweets de preguntas de inversionistas.
                        Es
                        importante mencionar que el uso del video streaming no se limita a la presentación de los
                        resultados
                        trimestrales, en mercados como el británico, también es usado en:</p>

                    <img className="w-100 wow fadeIn" src={info1} alt="" data-wow-delay="0.2s" data-wow-duration="2.0s"/>
                    <p className={"mt-10"}>Siendo las empresas tecnológicas y de telecomunicaciones las que hacen un uso
                        más
                        profundo del video.</p>
                    <small>(*) Encuesta conducida en 2001 por SimplyWebcast a 62 empresas del Fortune 100, y a 47
                        empresas
                        del FTSE
                        100.
                    </small>
                    <br/>
                    <small>(**) Ver la serie de White papers, PR Rising, producida por PR Newswire.
                    </small>

                </div>
            </div>
        </Single>
    )
}

export default Detail
